
import OrderHead from './components/OrderHead'
import OrderAddress from './components/OrderAddress'
import OrderGiftCardProduct from './components/OrderGiftCardProduct'
import OrderShipping from './components/OrderShipping'
import OrderPay from './components/OrderPay'
import OrderCoupon from './components/OrderCoupon'
import OrderWallet from './components/OrderWallet'
import OrderGiftCardAmt from './components/OrderGiftCardAmt'
import OrderProduct from './components/OrderProduct'
import OrderNote from './components/OrderNote'
import OrderSettle from './components/OrderSettle'
import OrderSubtotal from './components/OrderSubtotal'
import OrderSafe from './components/OrderSafe'
import OrderSubmit from './components/OrderSubmit'

export default {
    name: 'Order',
    components: {
        OrderHead,
        OrderAddress,
        OrderShipping,
        OrderPay,
        OrderGiftCardProduct,
        OrderCoupon,
        OrderWallet,
        OrderGiftCardAmt,
        OrderProduct,
        OrderNote,
        OrderSettle,
        OrderSubtotal,
        OrderSafe,
        OrderSubmit
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            hasLoading: true,
            skeleton: true,
            query: {},
            currency: {},
            middleEmit: {},
            address: {},           // 下单地址
            shipping: {},          // 运输方式
            pay: {},               // 支付方式
            coupon: {},            // 优惠券
            wallet: {},            // 钱包
            giftCardAmt: {},       // 礼品卡金额
            note: {},              // 留言
            stoPlaceProduct: '',   // 缓存商品
            biz: {                 // 业务数据
                standby: '',       // 延迟发货
            },
            placeProduct: {        // 下单商品
                quantity: 0,
                list: []
            },
            giftCardProduct: {     // 礼品卡商品
                params: {},
                list: []
            },
            orderInfo: {},         // 订单信息
            abandonOrderInfo: {},  // 弃单信息
            giftCardOrderInfo: {}, // 礼品卡订单信息
            params: {
                orderAmt: {},      // 获取订单金额参数
            },
            back: {                // 返回
                hasBack: false,
                visible: false,
                hasTip: false,
                tipType: '',
                tipTitle: '',
                tipDesc: '',
                tipList: []
            },
            headerOrder:{}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    beforeRouteEnter (to, from, next) {
        if(['Coupon', 'CouponProduct', 'CouponChoose', 'Address', 'AddressList'].includes(from.name)) {
            let clear = setTimeout(() => {
                clearTimeout(clear)
                location.reload()
            }, 10)
        }
        next()
    },
    created() {
        this.initialSet()
    },
    mounted() {
        this.initialSetMounted()
        this.getOrderInfoInitial()
        this.setAddListener()
    },
    destroyed() {
        window.removeEventListener('popstate', this.setBack, false)
    },
    methods: {
        // 获取订单数据
        getAbandonOrderAjax() { // 弃单-获取弃单数据
            return new Promise((resolve, reject) => {
                this.$api.order.getAbandonOrder({
                    orderId: this.query.orderId
                }).then(response => {
                    const res = response?.result || {};
                    this.abandonOrderInfo = res || {}
                    this.setAbandonOrderData()
                    resolve(res)
                })
            })
        },
        setAbandonOrderData() { // 弃单-数据处理
            let placeProduct = [];
            const list = this.abandonOrderInfo.orderDetail.yfnOrderItem;
            list.forEach(p => {
                placeProduct.push({
                    ...p,
                    skuPic: p.mainPic,
                    customizeInfo: p.customizeParams,
                    customizeTotalFee: p.customizeFee,
                    quantity: p.quantityOrdered,
                    spuId: p.yfnSpuId,
                    skuId: p.yfnSkuId,
                })
            });
            this.placeProduct.list = placeProduct
            const yfnAddress = JSON.parse(this.abandonOrderInfo.orderDetail.yfnAddress);
            let orderRef = {
                ...yfnAddress,
                englishName:yfnAddress.country,
                state: yfnAddress.stateOrRegion,
                showName:yfnAddress.country,
                phone: yfnAddress.phoneNumber,
                addressLine1: yfnAddress.address1,
                addressLine2: yfnAddress.address2,
                postCode: yfnAddress.postalCode,
            }
            const aboAddress = this.$storage.get('order/aboAddress')
            this.$storage.set('order/placeAddress', aboAddress||orderRef)
        },
        getOrderInfoAjax() { // 获取下单信息
            const address = this.$storage.get('order/placeAddress') || {};
            let paramsObj = {
                ...this.params.orderAmt,
                ...this.coupon.params,
                shippingMethod: this.shipping.method,
                paymentMethod: this.pay.type,
                addressParam: address
            };
            // 钱包
            if(this.wallet.useWalletAmt !== undefined && this.wallet.useWalletAmt !== '') {
                paramsObj.useWalletAmt = (this.wallet.useWalletAmt || 0) * 100
            }
            // 礼品卡金额
            if(this.giftCardAmt.useGiftCardAmt !== undefined && this.giftCardAmt.useGiftCardAmt !== '') {
                paramsObj.useGiftCardAmt = (this.giftCardAmt.useGiftCardAmt || 0) * 100
            }
            return new Promise((resolve, reject) => {
                this.$api.order.getOrder({
                    data: JSON.stringify(paramsObj)
                }, this.hasLoading,{
                    countryCode:this.headerOrder?.countryCode,
                    useCurrencyCode:this.headerOrder?.currencyCode
                }).then(response => {
                    const res = response?.result || {};
                    res.shipping?.map(res=>{
                        res.selected ? this.$storage.set('order/shippingMethod',res.name) : ''
                    })
                    this.klaviyoSbr(res)
                    this.orderInfo = res
                    !this.orderInfo.useCurrencyAmtDetail && (this.orderInfo.useCurrencyAmtDetail = {})
                    response.code == 'success' && resolve(res)
                })
            })
        },
        klaviyoSbr(res){
            let items = []
            res.shopBag.map(item=>{
                items.push({
                    ProductID: item.productCode,
                    SKU: item.productSellerSku || item.skuCode,
                    ProductName: item.productName,
                    Quantity: item.quantity,
                    ItemPrice: Number(item.displaySkuPrice),
                    RowTotal: Number((Number(item.displaySkuPrice)*item.quantity).toFixed(2)),
                    ProductURL: `${window.location.origin}/${item.seoUrl}`,
                    ImageURL: item.skuPic,
                })
            })
            // klaviyo
            this.$fnApi.buried.klaviyo({
                type:'checkout',
                params:{
                    $event_id: this.$storage.get('userId'),
                    $value: Number(res.useCurrencyAmtDetail.displayTotalAmount),
                    CheckoutURL: `${window.location.origin}/shoppingbag`,
                    Items:items
                }
            })
        },
        setPlaceProduct() { // 获取订单信息-设置商品数据
            const list = this.placeProduct.list || [];
            let spuId = [];
            let orderItems = [];
            let quantity = 0
            list.forEach((p, pI) => {
                // 定制
                let cusObj = {};
                if(p.customizeId) {
                    cusObj.customizeId = p.customizeId
                    cusObj.customizeTotalFee = p.customizeTotalFee
                    cusObj.customizeInfo = p.customizeParams
                }
                // 下单参数
                spuId.push(p.spuId)
                let orderItemObj = {
                    spuId: p.spuId,
                    skuId: p.skuId,
                    quantity: p.quantity || 1,
                    goodsType: p.goodsType,
                    pitchOnNewcomerGoods: p.pitchOnNewcomerGoods || false,
                    customizeType: p.customizeType || 1,
                    vipQuantity: p.vipQuantity,
                    id: p.id,
                    ...cusObj
                };
                orderItems.push(orderItemObj)
                quantity += p.quantity
            })
            this.params.orderAmt.spuId = spuId
            this.params.orderAmt.orderItems = orderItems
            this.placeProduct.quantity = quantity
        },
        getGiftCardOrderAjax() { // 礼品卡-获取礼品卡下单信息
            const giftCardProductList = this.giftCardProduct.list || []
            const firstCard = giftCardProductList[0] || {}
            return new Promise((resolve) => {
                this.$api.order.getGiftCardOrder({
                    cardId: firstCard.cardId,
                    amountId: firstCard.amountId,
                }).then(response => {
                    const res = response?.result || {};
                    this.giftCardOrderInfo = res
                    if(response.code == 'success') {
                        resolve(res)
                    }
                })
            })
        },
        getOrderInfoInitial() { // 获取订单信息-初始化
            if(this.query.orderId) {
                this.getAbandonOrderAjax().then(res => {
                    this.getCoupon()
                })
            } else {
                if(this.query.from == 'giftCard') {
                    // 礼品卡
                    this.getGiftCardOrderAjax().then(res => {
                        this.placeProduct.list = this.giftCardOrderInfo.shopBag
                        this.orderInfo = res
                        !this.orderInfo.useCurrencyAmtDetail && (this.orderInfo.useCurrencyAmtDetail = {})
                        this.setOrderData()
                    })
                } else {
                    this.stoPlaceProduct && this.getCoupon()
                }
            }
        },
        getOrderInfo(obj = {}) { // 获取订单信息-change
            this.hasLoading = obj.hasLoading ?? true
            this.setPlaceProduct()
            this.getOrderInfoAjax(obj).then(res => {
                this.setOrderData()
            })
        },
        setOrderData() { // 获取订单信息-处理订单数据
            // 商品数据
            this.placeProduct.list = this.orderInfo.shopBag || []
            if(this.placeProduct.list.length) {
                this.$storage.set('order/placeProduct', this.placeProduct.list)
                this.skeleton = false
            }
            this.placeProduct.list.forEach(p => {
                if(p.standby) {
                    this.biz.standby = p.standby
                }
            })
        },
        // emit
        setAddress(obj) { // 地址
            this.address = obj
        },
        handleShipping(obj) { // 运输方式点击
            this.shipping = obj
        },
        handlePay(obj) { // 支付方式点击
            this.pay = obj
            this.pay.type && (this.orderInfo.useCurrencyAmtDetail.currentUsePayment = this.pay.type)
        },
        handleCode(obj) { // 优惠券点击
            this.coupon = obj
        },
        getCoupon() { // 获取优惠券
            const ref = this.$refs.OrderCoupon;
            if(ref) {
                this.setPlaceProduct()
                let coupon = this.$storage.get('order/coupon')
                let code = this.$route.query?.giveUpCouponCode||coupon.code
                this.$api.common.getCountryList().then(response => {
                    const address = this.$storage.get('order/placeAddress') || {};
                    this.headerOrder = response?.result?.find(res=>{
                        return address.englishName==res.englishName
                    })
                    let cry;
                    if(this.headerOrder?.currencyCode){
                        this.$storage.set('countryStorage', this.headerOrder, true)
                        cry = {code:this.headerOrder.currencyCode,symbol:this.headerOrder.currencySymbol}
                    }
                    this.currency = cry || this.$storage.get('currency') || {}
                    ref.getCoupon({code:code,headerOrder:this.headerOrder}).then(res => {
                        this.coupon = res
                        this.getOrderInfo()
                    })
                })
            }
        },
        inputNote(obj) { // 留言
            this.note = obj
        },
        changeWalletData(obj) { // 钱包
            this.wallet = obj
        },
        changeGiftCardAmtData(obj) { // 礼品卡金额使用
            this.giftCardAmt = obj
        },
        getKeyData(key) { // 获取值
            return this[key]
        },
        getRef(key) { // 获取ref
            return this.$refs[key]
        },
        cardPayEmbed(obj) {
            const ref = this.$refs.OrderPay;
            ref && ref.cardPayEmbed(obj)
        },
        handleBack() { // 返回
            this.setBack()
        },
        setHandleBack() { // 返回-操作
            this.$storage.remove('order/cardPayInfo')
            if(this.query.from == 'cart') {
                if(window.history.length <= 1) {
                    this.$router.push({
                        name: 'Home'
                    })
                } else {
                    this.$router.push({
                        name: 'Bag'
                    })
                }
            } else if(this.query.from == 'giftCard') {
                this.$router.push({
                    name: 'GiftCard'
                })
            } else {
                this.$router.back()
            }
            this.$storage.remove('order/payMethod')
        },
        setBackTip() { // 返回-提示
            let type = 4; // 1 仅免邮 2 仅折扣 3 免邮和折扣 4 不含免邮和折扣
            // const shippingTip = !this.shipping.fee ? this.$translate('free shipping') : '';
            let hasExistFee = false;
            if(this.shipping.list) {
                this.shipping.list.forEach(p => {
                    if(!p.fee) {
                        hasExistFee = true
                    }
                })
            }
            const shippingTip = hasExistFee ? this.$translate('free shipping') : '';
            const couponAmount = this.coupon?.info?.displayPercentAmount;
            const couponTip = couponAmount ? `<i>${this.$translate('a')}</i> ${this.$price(couponAmount, this.currency.symbol)} ${this.$translate('discount')}` : '';
            const hasSymbol = !!(shippingTip && couponTip);
            if(this.shipping.fee) {
                type = 1
            }
            if(couponAmount) {
                type = 2
            }
            if(!this.shipping.fee && couponAmount) {
                type = 3
            }
            this.back.tipType = type
            this.back.tipTitle = this.$translate(`You haven’t finished checking out yet. Don’t miss out on`)
            // this.back.tipDesc = type != 2 ? this.$translate(`Don’t miss out on`) : this.$translate(`Don’t miss out a`)
            this.back.hasTip = !!shippingTip || !!couponTip
            this.back.tipList = [{
                desc: shippingTip,
                icon: 'icon-free-circle',
                text: this.$translate('Free shipping'),
                color: '#F8981C',
                visible: !!shippingTip
            }, {
                desc: this.$translate('&'),
                color: '#000',
                fontFamily: 'Mulish-Regular',
                visible: hasSymbol
            }, {
                desc: couponTip,
                icon: 'icon-discount-circle',
                text: `${this.$translate('Saved')} ${this.$price(couponAmount, this.currency.symbol)}`,
                color: '#F8981C',
                visible: !!couponTip
            }]
        },
        // 返回操作
        setAddListener() {
            // const hasRefresh = this.$store.state.biz.hasRefresh;
            // let hasPushState = !hasRefresh;
            // if(window.history && window.history.pushState) {
            //     hasPushState && history.pushState(null, null, document.URL);
            //     window.addEventListener('popstate', this.setBack, false)
            // }
        },
        setBack() {
            this.setBackTip()
            if(this.back.hasBack || !this.back.hasTip) {
                this.setHandleBack()
            } else {
                // history.pushState(null, null, document.URL);
                this.back.visible = true
                this.back.hasBack = false
                return false
            }
        },
        handleBackNo() {
            this.back.hasBack = true
            this.back.visible = false
            this.setBack()
        },
        handleBackKeep() {
            this.back.visible = false
            this.back.hasBack = false
        },
        handleSave() {
            this.back.hasBack = true
            this.back.visible = false
        },
        getContainer() {
            return document.querySelector('.order')
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Order Confirmation'),
                keywords: this.$translate('Order Confirmation'),
                description: this.$translate('Order Confirmation'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            // query.from -> cart 购物车 giftCard 礼品卡
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
            this.query = this.$route.query
            !this.query.from && (this.query.from = 'cart')
            this.currency = this.$storage.get('currency') || {}
        },
        initialSetMounted() {
            const storage = this.$storage.getAll() || {};
            this.placeProduct.list = storage['order/placeProduct'] || []
            this.shipping.method = storage['order/shippingMethod'] || ''
            this.pay.type = storage['order/payMethod'] || ''
            this.hasLogin = this.$login()
            this.stoPlaceProduct = storage['order/placeProduct'] || ''
            this.giftCardProduct.list = storage['order/placeGiftCard'] || []
            this.giftCardProduct.params = storage['order/placeGiftCardParams'] || []
            if(!storage['order/placeProduct'] && !this.query.orderId) {
                this.$router.replace({
                    name: 'Bag'
                })
            }
        }
    },
}
